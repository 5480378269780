import React from 'react';
import { Link as MuiLink } from '@mui/material';
import { Link } from "react-router-dom";

export default function ProjectIntro() {

    return (
        <div className='flex flex-col items-center mt-[150px] max-w-full w-[1024px]'>
            <div ckassName='flex flex-col max-w-full'>
                <div className='font-poppins font-medium text-[#92A64B] text-[55px]'>
                    Recalls
                </div>
                <span className='text-[18px] text-[#9C9C9C]'>Redesigning a government website for accessing product recalls across industries.</span>
            </div>

            <img className='mt-[32px]' src={'/assets/Recalls/ProjectIntro.png'}></img>

            <section className='flex flex-row w-full mt-[150px] text-left justify-between'>
                <div className='flex flex-col w-[290px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>My Role</span>

                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>Designer </span><span>- Lead, Information</span>
                    </div>
                    <span className='text-[18px] text-[#555555]'>Architecture, Prototyping</span>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>With</span>
                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>Sathya Raj, Mary Jendrzey</span>
                    </div>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>Timeline & Status</span>
                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>3 weeks </span>
                    </div>
                </div>


                <div className='flex flex-col w-[470px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>Overview</span>

                    <div className='mt-[12px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span className='font-bold'>Recalls.gov</span><span>, like many </span><span className='font-bold'>government websites,</span>
                        </div>
                        <div>
                            <span>suffered from an </span><span className='font-bold'>outdated design</span><span> that hindered</span>
                        </div>
                        <div>
                            <span className='font-bold'>usability</span><span> and </span><span className='font-bold'>accessibility</span><span>. As a platform designed to</span>
                        </div>
                        <div>
                            <span>inform users about recalls across industries like </span><span className='font-bold'>food,</span>
                        </div>
                        <div>
                            <span className='font-bold'>vehicles</span><span>, and </span><span className='font-bold'>consumer goods</span><span>, its shortcomings</span>
                        </div>
                        <div>
                            <span>created barriers for people trying to access </span><span className='font-bold'>vital safety</span>
                        </div>
                        <div>
                            <span className='font-bold'>information.</span>
                        </div>
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>As part of a task to </span><span className='font-bold'>redesign a government website</span><span>, I</span>
                        </div>
                        <div>
                            <span>transformed Recalls.gov's </span><span className='font-bold'>Food section</span><span>, the most</span>
                        </div>
                        <div>
                            <span>frequently accessed area, into a </span><span className='font-bold'>modern, user-first</span>
                        </div>
                        <div>
                            <span className='font-bold'>platform</span><span>. By improving </span><span className='font-bold'>navigation</span><span>, restructuring</span>
                        </div>
                        <div>
                            <span className='font-bold'>content</span><span>, and optimizing for </span><span className='font-bold'>mobile</span><span>, the redesign</span>
                        </div>
                        <div>
                            <span>made safety information </span><span className='font-bold'>accessible for everyone</span><span>.</span>
                        </div>
                        <div className='font-semibold text-[18px] text-[#0000EE]'><MuiLink href="https://docs.google.com/presentation/d/1j469iOOxQu7mBFrXdk_g9QClJXGm7cFGbvAOIWluv1I/edit#slide=id.gceaf5330b9_0_190">[READ FULL CASE STUDY HERE]</MuiLink></div>
                    </div>
                </div>
            </section>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
            <img className='self-center w-[1024px] mt-[150px] mx-auto' src={'/assets/Recalls/Highlights.png'}/>
            <Link to='/reveal'><img className='self-center w-[400px] mt-[150px] mx-auto' src={'/assets/Recur/NextCase.png'}/></Link>

        </div>
    )
}
