import "../styles/App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./header/Header";
import Home from "./portfolio/HomePage";
import AboutMe from "./about_me/AboutMe";
import Contact from "./contact/Contact";
import Recur from "./recur/RecurPage";
import Reveal from "./reveal/RevealPage";
import Recalls from "./recalls/RecallsPage";
import React, { createRef } from "react";

function App() {
  const homePageRef = createRef(); // Ref for HomePage

  const scrollToWork = () => {
    if (homePageRef.current) {
      homePageRef.current.scrollToProjects(); // Call the method in HomePage
    }
  };

  return (
    <div className="App">
      <Header onWorkClick={scrollToWork} />

      <Routes>
        <Route path="/" element={<Home ref={homePageRef} />} />
        <Route path="/aboutMe" element={<AboutMe />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/recur" element={<Recur />} />
        <Route path="/reveal" element={<Reveal />} />
        <Route path="/recalls" element={<Recalls />} />
      </Routes>
    </div>
  );
}

export default App;
