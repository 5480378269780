import React from 'react';

export default function Ideation() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#A7D3FF] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Definition & Ideation</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Translating frustrations into features.
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Focusing on defining Recur's core principles.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>To tackle </span><span className='font-bold'>subscription fatigue</span><span>, we prioritized features</span>
                            </div>
                            <div>
                                <span >that would simplify the experience:</span>
                            </div>
                        </div>
    
                        <div className="flex flex-col justify-center max-w-full mt-[24px]">
                            <img src='/assets/Recur/Definition.png' />
                        </div>

                        <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>To ground our ideas, I grouped the findings into</span>
                            </div>
                            <div>
                              <span className='font-bold'>actionable themes</span><span>, sparking </span><span className='font-bold'>ideation sessions</span><span> with my</span>
                            </div>
                            <div>
                                <span>team. We sketched </span><span className='font-bold'>early concepts</span><span>and prioritized</span>
                            </div>
                            <div>
                                <span>features based on </span><span className='font-bold'>user needs, feasibility</span><span>, and </span><span className='font-bold'>impact.</span>
                            </div>
                            <div>
                                <span >Our key solutions included:</span>
                            </div>
                        </div>
        
                    </div>
                </div>
    
                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/Solutions.png'}/>
                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/Sketch.png'}/>
    
                <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
            </section>
        );
}