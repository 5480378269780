import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Header({ onWorkClick }) {
  const navigate = useNavigate();

  const handleWorkClick = () => {
    navigate("/"); // Navigate to the homepage
    setTimeout(() => {
      if (onWorkClick) {
        onWorkClick(); // Trigger the smooth scroll
      }
    }, 0); // Ensure scroll happens after navigation
  };

  return (
    <div className="bg-[#F9F9F9] flex flex-wrap gap-10 justify-between items-center px-48 py-3.5 w-full text-base font-medium leading-tight whitespace-nowrap border-b border-neutral-700 min-h-[92px] text-neutral-700 max-md:px-5 max-md:max-w-full">
      <Link to="/">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8f833c1dc1dcd9cbe46e6673a42df2c248b414285df43552051e79ec442c711?placeholderIfAbsent=true&apiKey=a18d5bf9a3794c25a9b5c34fb7df9700"
          alt="Portfolio logo"
          className="object-contain shrink-0 self-stretch my-auto aspect-[0.78] w-[50px]"
        />
      </Link>
      <div className="flex gap-3 justify-center items-center self-stretch my-auto">
        <div className="flex flex-col justify-center self-stretch my-auto w-[42px]">
          <div
            onClick={handleWorkClick}
            className="cursor-pointer font-poppins text-[16px]"
          >
            Work
          </div>
        </div>
        <div className="self-stretch my-auto font-poppins">/</div>
        <div className="flex flex-col justify-center self-stretch my-auto w-[49px]">
          <Link to="/aboutMe">
            <div className="font-poppins text-[16px]">About</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
