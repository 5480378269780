import React from 'react';
import { AnimatedList } from './AnimatedList';
import { cn } from '../../lib/utils';
import { Link as MuiLink } from '@mui/material';

export default function ProjectIntro() {
    let notifications = [
        {
            name: "Netflix",
            description: "Entertainment",
            time: "5m ago",
            icon: <img src={'/assets/Recur/Netflix.png'}/>,
        },
        {
            name: "Hulu",
            description: "Entertainment",
            time: "Sunday, 9:23 am",
            icon: <img src={'/assets/Recur/Hulu.png'}/>,
        },
        {
            name: "Spotify",
            description: "Music",
            time: "Yesterday, 21:14",
            icon: <img src={'/assets/Recur/Spotify.png'}/>,
        },
        {
            name: "NY Times Digital",
            description: "News",
            time: "8/7/24",
            icon: <img src={'/assets/Recur/NYT.png'}/>,
        },
        {
            name: "HelloFresh",
            description: "Food",
            time: "6h ago",
            icon: <img src={'/assets/Recur/HelloFresh.png'}/>,
        },
        {
            name: "Adobe Creative Cloud",
            description: "Software",
            time: "4m ago",
            icon: <img src={'/assets/Recur/Adobe.png'}/>,
        },
        {
            name: "Lifetime",
            description: "Fitness",
            time: "20m ago",
            icon: <img src={'/assets/Recur/Lifetime.png'}/>,
        },
        {
            name: "Duolingo Plus",
            description: "Education",
            time: "8/8/2024",
            icon: <img src={'/assets/Recur/Duolingo.png'}/>,
        },
        {
            name: "Amazon Prime",
            description: "Shopping",
            time: "2m ago",
            icon: <img src={'/assets/Recur/AmazonPrime.png'}/>,
        },
        {
            name: "Disney+",
            description: "Entertainment",
            time: "5m ago",
            icon: <img src={'/assets/Recur/Disney.png'}/>,
        },
      ];
       
      notifications = Array.from({ length: 10 }, () => notifications).flat();

      const Notification = ({ name, description, icon, color, time }) => {
        return (
          <figure
            className={cn(
              "relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-2xl p-4",
              // animation styles
              "transition-all duration-200 ease-in-out hover:scale-[103%]",
              // light styles
              "bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
            )}
          >
            <div className="flex flex-row items-center gap-3">
              <div
                className="flex size-10 items-center justify-center rounded-2xl"
                style={{
                  backgroundColor: color,
                }}
              >
                <span className="text-lg">{icon}</span>
              </div>
              <div className="flex flex-col overflow-hidden font-poppins text-[#555555]">
                <figcaption className="flex flex-row items-center whitespace-pre">
                  <span className="text-[18px] font-medium">{name}</span>
                  <span className="mx-1">·</span>
                  <span className="text-[14px] font-medium text-[#8F8F8F]">{time}</span>
                </figcaption>
                <p className="text-[14px] font-medium text-left">
                  {description}
                </p>
              </div>
            </div>
          </figure>
        );
      };

    return (
        <div className='flex flex-col items-center mt-[150px] max-w-full w-[1024px]'>
            <div ckassName='flex flex-col max-w-full'>
                <div className='font-poppins font-medium text-[#607185] text-[55px]'>
                    Recur
                </div>
                <span className='text-[18px] text-[#9C9C9C]'>A mobile app that simplifies subscription management</span>
            </div>

            <img className='mt-[32px]' src={'/assets/Recur/ProjectIntro.png'}></img>

            <section className='flex flex-row w-full mt-[150px] text-left justify-between'>
                <div className='flex flex-col w-[270px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>My Role</span>

                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>Designer </span><span>- Research, Feature</span>
                    </div>
                    <span className='text-[18px] text-[#555555]'>Scoping, Prototyping</span>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>With</span>
                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>Chloe Kim, Jordan McDonald, </span>
                        <span className='font-semibold'>Eric Shim, Adrian Garcia </span>
                    </div>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>Timeline & Status</span>
                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>4 weeks </span>
                    </div>
                </div>


                <div className='flex flex-col w-[480px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>Overview</span>

                    <div className='mt-[12px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>With the growing </span><span className='font-bold'>challenge of managing multiple</span>
                        </div>
                        <div>
                            <span className='font-bold'>digital subscriptions</span><span>, users often face </span><span className='font-bold'>financial</span>
                        </div>
                        <div>
                            <span className='font-bold'>strain</span><span> and </span><span className='font-bold'>frustration</span><span> due to </span><span className='font-bold'>missed cancellations</span>
                        </div>
                        <div>
                            <span className='font-bold'>and scattered tracking.</span>
                        </div>
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span className='font-bold'>I led the user research </span><span>and contributed to the </span><span className='font-bold'>design</span>
                        </div>
                        <div>
                            <span className='font-bold'>direction </span><span>for Recur, a mobile app designed to </span><span className='font-bold'>simplify</span>
                        </div>
                        <div>
                            <span className='font-bold'>subscription management</span><span>. My rolve involed</span>
                        </div>
                        <div>
                            <span className='font-bold'>uncovering pain points, prioritizing key features</span>
                        </div>
                        <div>
                            <span>like </span><span className='font-bold'>notifications </span><span>and </span><span className='font-bold'>spending insights</span><span>, and crafting</span>
                        </div>
                        <div>
                            <span className='font-bold'>intuitive solutions </span><span>that </span><span className='font-bold'>empower users</span><span> to regain</span>
                        </div>
                        <div>
                            <span>control of their subscriptions.</span>
                        </div>
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>Recur was praised for its </span><span className='font-bold'>seamless user experience</span><span>,</span>
                        </div>
                        <div>
                            <span>addressing a </span><span className='font-bold'>universal problem</span><span> with </span><span className='font-bold'>clarity and</span>
                        </div>
                        <div>
                            <span className='font-bold'>efficiency</span><span>, and showcasing strong </span><span className='font-bold'>potential for</span>
                        </div>
                        <div>
                            <span className='font-bold'>saving users time and money</span><span>.</span>
                        </div>
                        <div className='font-semibold text-[18px] text-[#0000EE]'><MuiLink href="https://docs.google.com/presentation/d/1NsZ5uhX4-UuFAL3Lg5wPScEZtRkanSAPyqNVRxBc-rk/edit#slide=id.p1">[READ FULL CASE STUDY HERE]</MuiLink></div>
                    </div>
                </div>
            </section>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
            <img className='self-center w-[1024px] mt-[150px] mx-auto' src={'/assets/Recur/Highlights.png'}/>
            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>

            <section className='mt-[150px] w-full items-left'>
                <div className='text-left text-[35px] text-[#555555] font-semibold'>The growing complexity of managing subscriptions.</div>

                <div className='flex flex-row justify-between text-left mt-[80px]'>
                    <div className='font-poppins text-[23px] text-[#555555] flex flex-col w-[456px]'>
                        <span>An expensive and overwhelming</span>
                        <span>challenege.</span>
                    </div>

                    <div className='w-[488px] text-[18px] flex flex-col leading-[1.7] text-[#555555]'>
                        <div className='flex-row'>
                            <span className='font-bold'>Digital subscription</span><span> bring convenience to our lives, but </span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>managing them</span><span> has become an </span><span className='font-bold'>uphill battle</span><span>. Without</span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>centralized tools</span><span>, users struggle to keep track of </span><span className='font-bold'>free</span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>trials, renewals, </span><span>and </span><span className='font-bold'>cancellations,</span><span> often leading to</span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>unexpected charges</span><span> and </span><span className='font-bold'>financial strain.</span>
                        </div>

                        <div className='mt-[24px]'>
                            <div className='flex-row'>
                                <span>This </span><span className='font-bold'>fragmented experience</span><span> leaves users</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>overwhelmed, frustrated, </span><span>and searching for a </span><span className='font-bold'>better</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>soltuions. Recur</span><span> was created to </span><span className='font-bold'>untagle this</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>complexity, empowering users</span><span> to take control of their</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>subscriptions with ease</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className= "mt-[80px] flex h-[690px] w-full flex-col p-6 overflow-hidden rounded-lg border bg-background">
                <AnimatedList>
                    {notifications.map((item, idx) => (
                        <Notification {...item} key={idx} />
                    ))}
                </AnimatedList>
            </div>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>

        </div>
    )
}
