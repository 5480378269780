import React from 'react';

export default function Testing() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#FFD8DA] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Design</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Turning Ideas into Experiences
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Mapping user interactions:
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>We designed </span><span className='font-bold'>user flows</span><span> that </span><span className='font-bold'>prioritized ease</span><span> and</span>
                            </div>
                            <div>
                                <span className='font-bold'>efficiency</span><span>, guiding first-time users through the app's key</span>
                            </div>
                            <div>
                              <span>features: scanning, decoding, and saving.</span>
                            </div>
                        </div>
                    </div>
                </div>
    
                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Reveal/UserFlow.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Building prototypes:
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>Prototypes were tested with users to validate design</span>
                            </div>
                            <div>
                                <span>decisions.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Reveal/MidFidelity.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Iterating for clarity:
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>User feedback revealed several opportunities to improve:</span>
                            </div>
                        </div>
                    </div>
                </div>

                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Reveal/Iterations.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Final Design.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>Using all of the data we gathered from research and</span>
                            </div>
                            <div>
                                <span>ideation phases, we came up with this prototype: </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%", height: "80vh", margin: 0, padding: 0 }}>
                  <iframe
                    style={{
                      border: "none",
                      width: "100%",
                      height: "100%",
                    }}
                    draggable="false"
                    src="https://embed.figma.com/proto/QIsR1x6gDG2tU5A6Ttxsrj/Reveal-Prototype?node-id=1-2314&p=f&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2314&embed-host=share" 
                    allowFullScreen
                    title="Figma Prototype"
                  ></iframe>
                </div>

                <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
    
            </section>
        );
}