import { Button } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";

export default function Impact() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#FFD8DA] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Conclusion</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Impact
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <div className='flex-col'>
                        <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                            Turning transparency into
                        </h2>
                        <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                            empowerment.
                        </h2>
                    </div>
                    
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>While </span><span className='font-bold'>Reveal</span><span> didn't launch, it earned </span><span className='font-bold'>high praise</span><span> for its</span>
                            </div>
                            <div>
                                <span className='font-bold'>clear vision</span><span> and </span><span className='font-bold'>thoughtful design</span><span>. Stakeholders</span>
                            </div>
                            <div>
                                <span>recognized its potential to bridge a </span><span className='font-bold'>critical gap</span><span> in </span><span className='font-bold'>pet</span>
                            </div>
                            <div>
                                <span className='font-bold'>food transparency</span><span>, empowering </span><span className='font-bold'>pet parents</span><span> to make</span>
                            </div>
                            <div>
                                <span className='font-bold'>informed decisions</span><span> with ease.</span>
                            </div>
                        </div>

                        <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>The project was shaped by </span><span className='font-bold'>tight time constraints</span><span>, which</span>
                            </div>
                            <div>
                              <span>meant we couldn't fully realize the app's development.</span>>
                            </div>
                            <div>
                                <span>However, the process demonstrated the value of a </span>>
                            </div>
                            <div>
                                <span className='font-bold'>focused scope</span><span> and </span><span className='font-bold'>collaborative design</span><span>, paving the way</span>
                            </div>
                            <div>
                                <span>for future possibilities.</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <div className='flex-col'>
                        <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                            Future Opportunities
                        </h2>
                    </div>
                    
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>To further its potential, Reveal could expand to:</span>
                            </div>

                            <div className='mt-[24px]'>
                              <img src='/assets/Reveal/Future.png'/>
                            </div>
                        </div>

                    </div>
                </div>
    
                <img className='self-center w-[680px] mt-[80px] mx-auto' src={'/assets/Reveal/Reflection.png'}/>

                <Link to='/recur'><img className='self-center w-[400px] mt-[80px] mx-auto' src={'/assets/Recur/NextCase.png'}/></Link>
            </section>
        );
}