import React from 'react';
import { Container, Grid, Typography, TextField, Box, Button, Link as MuiLink } from '@mui/material';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
        };
    }

    handleChange = (event) => {
        const { id, value } = event.target;
        this.setState({ [id]: value });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const { name, email, message } = this.state;

        const mailtoLink = `mailto:mam1061@txstate.edu?subject=Message from ${name}&body=${encodeURIComponent(message)}%0A%0AFrom: ${name}%0AEmail: ${email}`;
        window.location.href = mailtoLink;
    };

    render() {
        return (
            <Container>
                <Grid container spacing={2} sx={{ height: 'calc(100vh - 64px - 40px)', paddingTop: '100px' }}>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <Box sx={{ padding: '20px', height: '100%', paddingTop: '70px' }}>
                            <Typography variant="h5" component="div" textAlign={'left'} paddingBottom={'50px'}>
                                Thanks for stopping by!
                            </Typography>
                            <Typography variant="body1" component="div" textAlign={'left'}>
                                If you would like to request a resume or reach out with an opportunity, you can contact me via the form below or connect with me on <MuiLink href="https://www.linkedin.com/in/cristinamau/" target="_blank" rel="noopener">LinkedIn</MuiLink>.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Box sx={{ padding: '20px' }}>
                            <Typography variant="h5" component="div">
                                Contact Me
                            </Typography>
                            <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
                                <TextField
                                    required
                                    id="name"
                                    label="Name"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    required
                                    id="email"
                                    label="Email"
                                    type="email"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    id="message"
                                    label="Message"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    margin="normal"
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                />
                                <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                                    <Button variant="contained" color="primary" type="submit">
                                        Send Message
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        )
    }
};

export default Contact;