import React from 'react';
import { Marquee } from './Marquee';
import { cn } from '../../lib/utils';

export default function Research() {

    const reviews = [
        {
            name: "John",
            body: `“I don’t manage my subscriptions because it’s overwhelming to track them all manually.”`,
            img: '/assets/Recur/John.png',
        },
        {
            name: "Anna",
            body: `“I’ve subscribed to services thinking I’d cancel later, but I forgot and got charged.”`,
            img: '/assets/Recur/Anna.png',
        },
        {
            name: "James",
            body: `“I’ve forgotten to cancel free trials multiple times—it’s frustrating and costs me money.”`,
            img: '/assets/Recur/James.png',
        },
        {
            name: "Tish",
            body: `“It would be helpful to see all my subscriptions in one place so I can decide what’s worth keeping.” `,
            img: '/assets/Recur/Tish.png',
        },
        {
            name: "Katrina",
            body: `“I use Mint for budgeting, but it doesn’t really help with managing subscriptions specifically.”`,
            img: '/assets/Recur/Katrina.png',
        },
        {
            name: "Darius",
            body: `“I rely on email reminders to track my renewals, but sometimes they get lost in the clutter.” `,
            img: '/assets/Recur/Darius.png',
        },
        {
            name: "Leah",
            body: `“If there was an app to manage my subscriptions, I’d definitely use it—as long as it’s intuitive and not time-consuming.” `,
            img: '/assets/Recur/Leah.png',
        },
        {
            name: "Peter",
            body: `“A management tool would be great if it could notify me about upcoming charges and free trials ending.”`,
            img: '/assets/Recur/Peter.png',
        },
        {
            name: "Kenny",
            body: `“Sometimes I don’t realize how many subscriptions I have until I see a charge I wasn’t expecting.” `,
            img: '/assets/Recur/Kenny.png',
        },
        {
            name: "Tyler",
            body: `“Managing subscriptions isn’t something I think about daily, but I know I’m wasting money without proper tracking.”`,
            img: '/assets/Recur/Tyler.png',
        }
    ];

    const firstRow = reviews.slice(0, reviews.length / 2);
    const secondRow = reviews.slice(reviews.length / 2);

    const ReviewCard = ({
        img,
        name,
        body,
    }) => {
        return (
            <figure
                className={cn(
                    "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
                    // light styles
                    "border-gray-950/[.1] bg-[#FFFFFF] hover:bg-gray-950/[.05]",
                )}
            >
                <div className="flex flex-row items-center gap-2">
                    <img className="rounded-full" width="32" height="32" alt="" src={img} />
                    <div className="flex flex-col">
                        <figcaption className="text-[18px] font-poppins font-medium text-[#555555]">
                            {name}
                        </figcaption>
                    </div>
                </div>
                <blockquote className="mt-2 text-[14px] font-semibold text-[#555555] text-left">{body}</blockquote>
            </figure>
        );
    };

    return (
        <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
            <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#A7D3FF] rounded-full" />
                <span className='text-[14px] font-semibold' id="research-title">Research</span>
            </div>
            <div className="flex flex-col w-full text-left mt-[32px]">
                <h2 className="text-[35px] font-semibold text-[#555555] w-[488px]">
                    Understanding the problem.
                </h2>
            </div>
            <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                    Where it all begins.
                </h2>
                <div className="flex flex-col text-[#555555] w-[480px]">
                    <div className='text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>To truly understand the scope of the problem, I </span><span className='font-bold'>launched</span>
                        </div>
                        <div>
                            <span className='font-bold'>a survey with 129 participants </span><span>and </span><span className='font-bold'>conducted 9 user</span>
                        </div>
                        <div>
                            <span className='font-bold'>interviews</span><span>. These insights shaped our understanding of</span>
                        </div>
                        <div>
                            <span>user frustrations:</span>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center max-w-full mt-[24px]">
                        <img src='/assets/Recur/ResearchBegin.png' />
                    </div>
                </div>
            </div>
            <div className="mt-[80px] relative flex h-[700px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background">
                <Marquee pauseOnHover className="[--duration:20s]">
                    {firstRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <Marquee reverse pauseOnHover className="[--duration:20s]">
                    {secondRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white"></div>
                <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white"></div>
            </div>

            <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/Affinity.png'}/>
            <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/Guiding.png'}/>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
        </section>
    );
}