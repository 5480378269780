import { Button } from '@mui/material';
import React from 'react';
import { Link } from "react-router-dom";

export default function Impact() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#A7D3FF] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Conclusion</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Impact
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        What we achieved
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span className='font-bold'>Judges and colleagues</span><span> praised </span><span className='font-bold'>Recur's practicality</span><span>, with</span>
                            </div>
                            <div>
                                <span>many expressing </span><span className='font-bold'>interest in using the app themselves</span><span>.</span>
                            </div>
                        </div>

                        <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>Recur showed a strong</span><span className='font-bold'>potential for saving users</span><span> both</span>
                            </div>
                            <div>
                              <span className='font-bold'>time and money</span><span>, with </span><span className='font-bold'>85% reporting</span><span> that the</span>
                            </div>
                            <div>
                                <span className='font-bold'>personalized dashboard</span><span> and </span><span className='font-bold'>calendar feature</span><span> helped</span>
                            </div>
                            <div>
                                <span>them manage subscriptions more </span><span className='font-bold'>efficiently</span><span>.</span>
                            </div>
                        </div>

                        <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span className='font-bold'>Feedback from judges</span><span> validated its </span><span className='font-bold'>practicality</span><span>, further</span>
                            </div>
                            <div>
                              <span>proving that </span><span className='font-bold'>Recur</span><span> could become an </span><span className='font-bold'>essential tool</span><span> for</span>
                            </div>
                            <div>
                               <span>anyone </span><span className='font-bold'>juggling multiple subscriptions</span><span>.</span>
                            </div>
                        </div>
        
                    </div>
                </div>
    
                <img className='self-center w-[680px] mt-[80px] mx-auto' src={'/assets/Recur/Reflection.png'}/>

                <Link to='/recalls'><img className='self-center w-[400px] mt-[80px] mx-auto' src={'/assets/Recur/NextCase.png'}/></Link>
                
            </section>
        );
}