export const projects = [
    {
      year: "January 26th - February 10th ",
      title: "Recur",
      description: "A mobile app that simplifies subscriptions management.",
      tags: ["ux design", "mobile", "research"],
      image: "/assets/HomePage/Recur.png",
      hoverImage:  "/assets/HomePage/HoverRecur.png",
      dotColor: "bg-[#A7D3FF]",
      url: '/recur'
    },
    {
      year: "March 6th-22nd",
      title: "Recalls",
      description: "Redesigning a government website for accessing product recalls across industries.",
      tags: ["ux design", "desktop", "research"],
      image: "/assets/HomePage/Recalls.png",
      hoverImage:  "/assets/HomePage/HoverRecalls.png",
      dotColor: "bg-[#A7D3FF]",
      url: '/recalls'
    },
    {
      year: "April 28th - May 15th",
      title: "Reveal",
      description: "A mobile app that helps pet parents make informed and confident pet food choices.",
      tags: ["ux design", "mobile", "research"],
      image: "/assets/HomePage/Reveal.png",
      hoverImage:  "/assets/HomePage/HoverReveal.png",
      dotColor: "bg-[#A7D3FF]",
      url: '/reveal'
    },
  ];