import React, { createRef } from "react";
import { ProjectCard } from "./components/ProjectCard";
import { Footer } from "../footer/Footer";
import { projects } from "./data/projects";
import { Container, Box, Typography } from "@mui/material";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.projectRef = createRef(); // Ref for the ProjectCard section
  }

  // Method to scroll to the ProjectCard section
  scrollToProjects = () => {
    if (this.projectRef.current) {
      this.projectRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <Container maxWidth={false} className='bg-[#F9F9F9]!' sx={{ paddingBottom: "25px" }}>
        {/* Hero Section */}
        <Box
          sx={{
            marginTop: "200px",
            width: "950px",
            height: "600px",
            backgroundImage: "url(/assets/HomepageHeroMainNoText.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            justifySelf: "center",
            paddingTop: "73px",
          }}
        >
          <Box
            sx={{
              width: "441px",
              height: "212px",
              marginRight: "auto",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ textAlign: "left", fontSize: "23px", fontFamily: "Poppins" }}>
              Hi, I'm
            </Typography>
            <Box sx={{ width: "362px", height: "68px", marginBottom: "32px" }}>
              <Typography
                sx={{
                  fontSize: "55px",
                  textAlign: "left",
                  marginTop: "28px",
                  borderBottom: "1px solid #C8C8C8",
                  lineHeight: "120%",
                  fontFamily: "GamjaFlower",
                  width: "362px",
                  letterSpacing: "0px",
                }}
              >
                Cristina Mauricio
              </Typography>
              <Typography sx={{ color: "#C8C8C8" }}>NAME</Typography>
            </Box>
            <Typography sx={{ textAlign: "left", fontSize: "23px", fontFamily: "Poppins" }}>
              A UX designer turning problems
            </Typography>
            <Typography sx={{ textAlign: "left", fontSize: "23px", fontFamily: "Poppins" }}>
              into approachable solutions.
            </Typography>
          </Box>
        </Box>

        {/* Projects Section */}
        <div ref={this.projectRef}>
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>

        <Footer />
      </Container>
    );
  }
}

// Wrap HomePage with React.forwardRef
export default React.forwardRef((props, ref) => <HomePage {...props} ref={ref} />);
