import React from "react";
import { Typography, Container, Grid, Box, Avatar, Paper } from "@mui/material";

class AboutMe extends React.Component {
  render() {
    return (
      <Container>
        <Box
          sx={{
            marginTop: "50px",
            justifySelf: "center",
            height: "1087px",
            width: "794px",
            backgroundImage: "url(/assets/AboutMe.png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column",
            marginBottom: '50px'
          }}
        >
          <Box
            sx={{
              marginTop: "67px",
              height: "340px",
              textAlign: "left",
              display: "block",
              marginRight: "auto",
              paddingTop: "96px",
              paddingLeft: "80px",
            }}
          >
            <Typography fontSize='23px' >Hello hello! Nice to meet</Typography>
            <Typography fontSize='23px' >you, I'm Cristina!</Typography>

            <Typography fontSize='18px' marginTop='16px'>
              I’m a designer who loves finding creative ways
            </Typography>
            <Typography fontSize='18px'>
              to solve problems in fun and meaningful ways.
            </Typography>
          </Box>

          <Box
            sx={{
              marginTop: "16px",
              height: "340px",
              textAlign: "left",
              display: "block",
              marginRight: "auto",
              paddingLeft: "80px",
            }}
          >
            <Typography fontSize='18px'>During the pandemic, I stumbled upon UX</Typography>
            <Typography fontSize='18px'>Design while learning about front-end</Typography>
            <Typography fontSize='18px'>development and coding. It was there that I</Typography>
            <Typography fontSize='18px'>discovered my passion for UX Design and</Typography>
            <Typography fontSize='18px'>realized how much I had been consciously</Typography>
            <Typography fontSize='18px'>doing it my entire life.</Typography>

            <Typography fontSize='18px' marginTop='16px'>
              This realization led me to pursue a bachelor’s
            </Typography>
            <Typography fontSize='18px'>
              degree in Digital Media Innovation, where I not
            </Typography>
            <Typography fontSize='18px'>
              only honed the skills I already had but also
            </Typography>
            <Typography fontSize='18px'>
              learned how to create and build digital products
            </Typography>
            <Typography fontSize='18px'>through coding and design.</Typography>
          </Box>

          <Box
            sx={{
              marginTop: "16px",
              height: "340px",
              textAlign: "left",
              display: "block",
              marginRight: "auto",
              paddingLeft: "80px",
            }}
          >
            <Typography fontSize='18px'>
              Now, I strive to make products that not only{" "}
            </Typography>
            <Typography fontSize='18px'>solve problems but also make a meaningful</Typography>
            <Typography fontSize='18px'>difference in people's lives.</Typography>

            <Typography fontSize='18px' marginTop='16px'>
              When I’m not working on design, you’ll probably
            </Typography>
            <Typography fontSize='18px'>
              find me traveling, perfecting the perfect cup of
            </Typography>
            <Typography fontSize='18px'>
              matcha, playing around with ideas for my next
            </Typography>
            <Typography fontSize='18px'>project, or doing all three at once.</Typography>

            <Typography fontSize='18px' marginTop='52px'>Peace,</Typography>
            <Typography fontSize='18px'>Cristina</Typography>
          </Box>
        </Box>
      </Container>
    );
  }
}

export default AboutMe;
