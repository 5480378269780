import React from 'react';

export default function Testing() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#A7D3FF] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Prototyping & Testing</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Refining the app.
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Iterative design in action.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>The goal was to create an </span><span className='font-bold'>intuitive experience</span><span> that users</span>
                            </div>
                            <div>
                                <span >could navigate </span><span className='font-bold'>without friction</span><span>. We used a </span><span className='font-bold'>high-fidelity</span>
                            </div>
                            <div>
                              <span className='font-bold'>prototype</span><span> and conducted </span><span className='font-bold'>two rounds of usability</span>
                            </div>
                            <div>
                              <span className='font-bold'>testing</span><span>.</span>
                            </div>
                        </div>

                        <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>We asked participants to complete tasks such as</span>
                            </div>
                            <div>
                                <span className='font-bold'>onboarding, connecting a bank account</span><span>, and </span><span className='font-bold'>canceling</span>
                            </div>
                            <div>
                              <span className='font-bold'>a subscription</span><span>.</span>
                            </div>
                        </div>
                    </div>
                </div>
    
                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/UserTesting.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        What worked:
                    </h2>
                    <div className="flex flex-col w-[480px] mt-[24px]">
                      <img src='/assets/Recur/TestingWorked.png' />
                    </div>
                </div>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        What needed improvement:
                    </h2>
                    <div className="flex flex-col w-[480px]">
                    </div>
                </div>

                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Recur/Improvement.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Final Design.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>We addressed the </span><span className='font-bold'>issues</span><span> we found in </span><span className='font-bold'>user testing</span><span> in our</span>
                            </div>
                            <div>
                                <span >next </span><span className='font-bold'>iteration</span><span> of the screens and created this </span><span className='font-bold'>prototype:</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%", height: "80vh", margin: 0, padding: 0 }}>
                  <iframe
                    style={{
                      border: "none",
                      width: "100%",
                      height: "100%",
                    }}
                    draggable="false"
                    src="https://embed.figma.com/proto/LJ1vQzJm75g2OeknN9KwLL/Prototypes?node-id=1-3482&p=f&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=1%3A2325&embed-host=share"
                    allowFullScreen
                    title="Figma Prototype"
                  ></iframe>
                </div>

                <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
    
            </section>
        );
}