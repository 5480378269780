import React from "react";
import MuiLink from "@mui/material/Link";

export function ProjectCard({ year, title, description, tags, image, dotColor, url }) {
  return (
    <div className="flex flex-wrap gap-10 self-center items-center mt-[200px] max-w-full leading-tight w-[1211px] mx-auto">
      <div className="flex flex-col my-auto w-[314px]">
        <div className="flex flex-col w-full text-lg text-black whitespace-nowrap">
          <div className="flex items-center w-full">
            <div className={`flex shrink-0 my-auto w-2.5 h-2.5 ${dotColor} rounded-full`} />
            <div className="ml-[8px] my-auto text-[18px] text-[#444444] font-poorstory">{year}</div>
          </div>
        </div>
        <div className="flex flex-col mt-[32px] w-full">
          <div className="w-full text-[28px] text-[#2B2B2B] whitespace-nowrap text-left font-poppins">
            {title}
          </div>
          <div className="flex flex-col mt-[24px] w-full">
            <div className="text-[18px] text-[#555555] text-left">{description}</div>
            <div className="flex flex-wrap gap-2 items-start mt-[28px] max-w-full text-[12px] text-center text-[#444444] w-[314px]">
              {tags.map((tag, index) => (
                <div key={index} className="flex flex-col rounded-full w-[78px]">
                  <div className="px-[6px] py-[12px] rounded-full bg-[#FF8F8F] bg-opacity-20">
                    {tag}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MuiLink href={url} target="_blank" rel="noopener">
        <div className="overflow-hidden rounded-md h-[596px] w-[825px]">
          <img
            src={image}
            alt={`${title} project preview`}
            className="object-cover h-full w-full rounded-md transition-transform duration-500 ease-in-out hover:scale-110"
          />
        </div>
      </MuiLink>
    </div>
  );
}
