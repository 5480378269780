import React from 'react';

export default function Ideation() {
  return (
    <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
                <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                    <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#FFD8DA] rounded-full" />
                    <span className='text-[14px] font-semibold' id="research-title">Definition & Ideation</span>
                </div>
                <div className="flex flex-col w-full text-left mt-[32px]">
                    <h2 className="text-[35px] font-semibold text-[#555555] w-full">
                      Setting the Scope
                    </h2>
                </div>
                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        From questinos to calrity.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>The research findings led us to ask: </span><span className='font-bold'>How might we</span>
                            </div>
                            <div>
                                <span className='font-bold'>simplify ingredient labels to make them easier to</span>
                            </div>
                            <div>
                                <span className='font-bold'>understand? How might we save users time without</span>
                            </div>
                            <div>
                                <span className='font-bold'>compromising the trustworthiness of the</span>
                            </div>
                            <div>
                                <span className='font-bold'>information? And how might we empower pet parents</span>
                            </div>
                            <div>
                                <span className='font-bold'>to feel confident in their choices, even with budget</span>
                            </div>
                            <div>
                                <span className='font-bold'>constraints?</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Mapping the Main Flow.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>With these guiding questions, we mapped out features</span>
                            </div>
                            <div>
                                <span>that focused on three key interactions:</span>
                            </div>

                            <div className='mt-[24px]'>
                              <img src='/assets/Reveal/Flow.png'/>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-between w-full mt-[24px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Defining Reveal's boundaries.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>The app is </span><span className='font-bold'>not a platform for promoting specific</span>
                            </div>
                            <div>
                                <span className='font-bold'>brands</span><span> or </span><span className='font-bold'>replacing veterinary advice </span><span>but instead</span>
                            </div>
                            <div>
                                <span className='font-bold'>focuses on decoding ingredient labels, providing</span>
                            </div>
                            <div>
                                <span className='font-bold'>unbiased educational content</span><span>, and </span><span className='font-bold'>empowering</span>
                            </div>
                            <div>
                                <span className='font-bold'>informed decisions.</span>
                            </div>
                        </div>
                    </div>
                </div>
    
                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Reveal/Map.png'}/>

                <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                    <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                        Generating ideas.
                    </h2>
                    <div className="flex flex-col text-[#555555] w-[480px]">
                        <div className='text-[18px] text-[#555555] leading-[1.7]'>
                            <div>
                                <span>We used an </span><span className='font-bold'>empathy map</span><span> to visualize what pet parents</span>
                            </div>
                            <div>
                               <span className='font-bold'>say, think, feel, and do</span><span> during the shopping experience.</span>
                            </div>
                            <div>
                                <span>This revealed opportunities to reduce frustration and</span>
                            </div>
                            <div>
                                <span>guided three key design principles:</span>
                            </div>

                            <div className='mt-[24px]'>
                              <img src='/assets/Reveal/Principles.png'/>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <img className='self-center w-full mt-[80px] mx-auto' src={'/assets/Reveal/EmpathyMap.png'}/>
    
                <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
            </section>
        );
}