import React, { useEffect } from 'react';
import ProjectIntro from './ProjectIntro';
import Research from './Research';
import Ideation from './Ideation';
import Testing from './Testing';
import Impact from './Impact';
import { Footer } from '../footer/Footer';

export default function RecurPage() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: use "auto" for instant scrolling
    });
  }, []);

  return (
    <div className="flex overflow-hidden flex-col justify-center items-center bg-[#F9F9F9]">
        <ProjectIntro />
        <Research />
        <Ideation />
        <Testing />
        <Impact />
      <Footer/>
    </div>
  );
}