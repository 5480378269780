import * as React from "react";
import { Link as MuiLink } from '@mui/material';

export function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  return (
    <div className="flex flex-wrap gap-10 justify-between items-center mt-52 max-w-full w-[1211px] mx-auto max-md:mt-10 mb-[80px]">
      <div className="flex flex-wrap gap-0.5 items-center self-stretch my-auto text-sm leading-5 text-red-400 min-w-[240px] max-md:max-w-full">
        <img src="/assets/Footer/Elevator.png" className="object-contain self-stretch my-auto aspect-[1.05] min-w-[240px] w-[245px]"/>
        <div className="flex gap-1 self-stretch my-auto min-w-[240px] w-[254px]">
          <img src="/assets/Footer/Up.png" className="cursor-pointer mt-[50px] w-[25px] h-[25px]" onClick={scrollToTop}/>
          <img src="/assets/Footer/Text.png" className="w-[254px]" />
        </div>
      </div>
      <div className="flex flex-col self-stretch my-auto leading-tight min-w-[240px] w-[510px]">
        <div className="flex flex-col w-full">
          <div className="w-full text-black text-[55px] font-gamjaflower text-left">
            Thanks for stopping by!
          </div>
          <div className="flex flex-wrap items-start mt-6 w-full text-sm text-left">
            <div className="flex flex-col flex-1">
              <div className="uppercase text-[#555555] text-[14px]">Got any questions?</div>
              <div className="mt-[8px] text-black">Feel free to reach out via</div>
            </div>
            <div className="flex flex-col flex-1 ml-[8px] mr-[19px] mt-[20px]">
              <img src="/assets/Vector 186.png" />
            </div>
            <div className="flex flex-col flex-1">
              <div className="uppercase text-[#555555] text-[14px]">Socials</div>
              <div className="flex-row flex">
                <div className="mt-[8px] text-black"><MuiLink href="mailto:cristina_mau@outlook.com" target="_blank" rel="noopener">Email</MuiLink></div>
                <div className="mt-[8px] text-black px-[2px]">/</div>
                <div className="mt-[8px] text-black"><MuiLink href="https://www.linkedin.com/in/cristinamau/" target="_blank" rel="noopener">LinkedIn</MuiLink></div>
              </div>
              
              {/* <div className="mt-2 text-black"><MuiLink href="https://www.linkedin.com/in/cristinamau/" target="_blank" rel="noopener">LinkedIn</MuiLink>/Email</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}