import React, { useEffect } from 'react';
import ProjectIntro from './ProjectIntro';
import { Footer } from '../footer/Footer';

export default function RecallsPage() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: use "auto" for instant scrolling
    });
  }, []);

  return (
    <div className="flex overflow-hidden flex-col justify-center items-center bg-[#F9F9F9]">
        <ProjectIntro />
      <Footer/>
    </div>
  );
}