import React from 'react';
import { Marquee } from './Marquee';
import { cn } from '../../lib/utils';

export default function Research() {

    const reviews = [
        {
            name: "Mike",
            body: `“I just look at the back of the package, but there are too many products online to spend time researching.`,
            img: '/assets/Reveal/Mike.png',
        },
        {
            name: "Joshua",
            body: `“If the packaging could be in a simple block bag but it’s healthy and good for her, I’d get it.”`,
            img: '/assets/Reveal/Joshua.png',
        },
        {
            name: "Kim",
            body: `““I stick to one brand because it’s what my dog has been eating for years, and switching now might cause issues.”`,
            img: '/assets/Reveal/Kim.png',
        },
        {
            name: "Nestor",
            body: `“I choose food based on whether it’s grain-free and what the first ingredient is.”`,
            img: '/assets/Reveal/Nestor.png',
        },
        {
            name: "Jasmine",
            body: `“I avoid ingredients like dyes and fillers because they don’t seem beneficial.”`,
            img: '/assets/Reveal/Jasmine.png',
        },
        {
            name: "Kathryn",
            body: `“I don’t recognize most of the ingredients on the label, maybe only 30%.”`,
            img: '/assets/Reveal/Kathryn.png',
        },
        {
            name: "Loraine",
            body: `“If a product has recalls or bad reviews, I avoid it.”`,
            img: '/assets/Reveal/Loraine.png',
        },
        {
            name: "Gene",
            body: `“I’d be more inclined to buy a product with a clean look rather than something colorful and flashy.”`,
            img: '/assets/Reveal/Gene.png',
        },
        {
            name: "Celia",
            body: `“I value my pet’s health above all, even if it costs more, but it has to make sense within my budget.”`,
            img: '/assets/Reveal/Celia.png',
        },
        {
            name: "Zaria",
            body: `“I avoid buying certain brands because they have no nutrients and are usually filled with fillers.”`,
            img: '/assets/Reveal/Zaria.png',
        }
    ];

    const firstRow = reviews.slice(0, reviews.length / 2);
    const secondRow = reviews.slice(reviews.length / 2);

    const ReviewCard = ({
        img,
        name,
        body,
    }) => {
        return (
            <figure
                className={cn(
                    "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
                    // light styles
                    "border-gray-950/[.1] bg-[#FFFFFF] hover:bg-gray-950/[.05]",
                )}
            >
                <div className="flex flex-row items-center gap-2">
                    <img className="rounded-full" width="32" height="32" alt="" src={img} />
                    <div className="flex flex-col">
                        <figcaption className="text-[18px] font-poppins font-medium text-[#555555]">
                            {name}
                        </figcaption>
                    </div>
                </div>
                <blockquote className="mt-2 text-[14px] font-semibold text-[#555555] text-left">{body}</blockquote>
            </figure>
        );
    };

    return (
        <section className="flex flex-col mt-[150px] w-[1024px]" aria-labelledby="research-title">
            <div className="flex gap-3 items-center self-start text-lg leading-tight whitespace-nowrap text-[#9C9C9C] text-left">
                <div className="flex self-stretch my-auto w-2.5 h-2.5 bg-[#FFD8DA] rounded-full" />
                <span className='text-[14px] font-semibold' id="research-title">Research</span>
            </div>
            <div className="flex flex-col w-full text-left mt-[32px]">
                <h2 className="text-[35px] font-semibold text-[#555555] w-[488px]">
                    Unpacking the Problem
                </h2>
            </div>
            <div className="flex flex-wrap justify-between w-full mt-[80px] text-left">
                <div className='flex-col'>
                <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                    Understanding the challenges pet
                </h2>
                <h2 className="text-[23px] font-poppins font-medium text-[#555555] w-[488px]">
                    parents face.
                </h2>
                </div>
                
                <div className="flex flex-col text-[#555555] w-[480px]">
                    <div className='text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>To truly understand the challenges </span><span className='font-bold'>pet parents face</span><span>, we</span>
                        </div>
                        <div>
                            <span>conducted </span><span className='font-bold'>10 interviews </span><span>and a </span><span className='font-bold'>short survey</span><span>, focusing</span>
                        </div>
                        <div>
                            <span>on their </span><span className='font-bold'>experiences with pet food shopping</span><span>. Here we</span>
                        </div>
                        <div>
                            <span>found three major pain points:</span>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center max-w-full mt-[24px]">
                        <img src='/assets/Reveal/ResearchBegin.png' />
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>As one user shared: </span><span className='font-bold'>"I don't even know what half these</span>
                        </div>
                        <div>
                            <span className='font-bold'>ingredients are - how am I supposed to know if</span>
                        </div>
                        <div>
                            <span className='font-bold'>they're good or bad?"</span>
                        </div>
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span>These insights highlighted the need for a solution that</span>
                        </div>
                        <div>
                            <span>simplified the process while building trust in the</span>
                        </div>
                        <div>
                            <span>information provided.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-[80px] relative flex h-[700px] w-full flex-col items-center justify-center overflow-hidden rounded-lg border bg-background">
                <Marquee pauseOnHover className="[--duration:20s]">
                    {firstRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <Marquee reverse pauseOnHover className="[--duration:20s]">
                    {secondRow.map((review) => (
                        <ReviewCard key={review.username} {...review} />
                    ))}
                </Marquee>
                <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white"></div>
                <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white"></div>
            </div>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
        </section>
    );
}