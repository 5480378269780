import React from 'react';
import { Link as MuiLink } from '@mui/material';

export default function ProjectIntro() {

    return (
        <div className='flex flex-col items-center mt-[150px] max-w-full w-[1024px]'>
            <div ckassName='flex flex-col max-w-full'>
                <div className='font-poppins font-medium text-[#FF8F8F] text-[55px]'>
                    Reveal
                </div>
                <span className='text-[18px] text-[#9C9C9C]'>A mobile app that helps pet parents make informed and confident pet food choices.</span>
            </div>

            <img className='mt-[32px]' src={'/assets/Reveal/ProjectIntro.png'}></img>

            <section className='flex flex-row w-full mt-[150px] text-left justify-between'>
                <div className='flex flex-col w-[266px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>My Role</span>

                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>Designer </span><span>- Research, Feature</span>
                    </div>
                    <span className='text-[18px] text-[#555555]'>Scoping, Prototyping</span>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>With</span>
                    <div className='mt-[12px] text-[18px] text-[#555555] flex-col flex'>
                        <span className='font-semibold'>Della Molavi, Peter Smith,</span>
                        <span className='font-semibold'>Jordan McDonald, Dean Hawn</span>
                    </div>

                    <span className='font-poppins font-medium text-[#9C9C9C] mt-[48px]'>Timeline & Status</span>
                    <div className='mt-[12px] text-[18px] text-[#555555]'>
                        <span className='font-semibold'>3 weeks </span>
                    </div>
                </div>


                <div className='flex flex-col w-[488px]'>
                    <span className='font-poppins font-medium text-[#9C9C9C]'>Overview</span>

                    <div className='mt-[12px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span className='font-bold'>Pet food shopping</span><span> shouldn't feel like a guessing game.</span>
                        </div>
                        <div>
                            <span>With </span><span className='font-bold'>ingredient labels</span><span> packed with </span><span className='font-bold'>confusing terms</span>
                        </div>
                        <div>
                            <span>and </span><span className='font-bold'>misleading marketing claims</span><span>, pet parents are</span>
                        </div>
                        <div>
                            <span>often left wondering </span><span className='font-bold'>what they're really feeding</span>
                        </div>
                        <div>
                            <span className='font-bold'>their pets.</span>
                        </div>
                    </div>

                    <div className='mt-[24px] text-[18px] text-[#555555] leading-[1.7]'>
                        <div>
                            <span className='font-bold'>Reveal</span><span> was created to cut through the noise, giving </span><span className='font-bold'>pet</span>
                        </div>
                        <div>
                            <span className='font-bold'>parents </span><span>the tools they need to </span><span className='font-bold'>decode ingredient</span>
                        </div>
                        <div>
                            <span className='font-bold'>labels </span><span>, understand </span><span>nutritional impacts</span><span>, and make</span>
                        </div>
                        <div>
                            <span className='font-bold'>informed decisions</span><span> that prioritize their </span><span className='font-bold'>pets' health.</span>
                        </div>
                        <div className='font-semibold text-[18px] text-[#0000EE]'><MuiLink href="https://docs.google.com/presentation/d/1BQwXDw4BeFjIiZdj25ROzjAemtI_wUF-XNwqaNzOknA/edit#slide=id.g849bea8f8c_0_0">[READ FULL CASE STUDY HERE]</MuiLink></div>
                    </div>
                </div>
            </section>

            <section className='mt-[150px] w-full items-left'>
                <div className='text-left text-[35px] text-[#555555] font-semibold'>Context</div>

                <div className='flex flex-row justify-between text-left mt-[80px]'>
                    <div className='font-poppins text-[23px] text-[#555555] flex flex-col w-[456px]'>
                        <span>Helping pet parents navigate</span>
                        <span>confusing choices.</span>
                    </div>

                    <div className='w-[488px] text-[18px] flex flex-col leading-[1.7] text-[#555555]'>
                        <div className='flex-row'>
                            <span>Shopping for pet food can feel overwhelming, like reading</span>
                        </div>
                        <div className='flex-row'>
                            <span>a foreign language. Ingredient labels are full of complex</span>
                        </div>
                        <div className='flex-row'>
                            <span>terms, and even well-intentioned pet parents are left</span>
                        </div>
                        <div className='flex-row'>
                            <span>wondering: What am I really feeding my pet?</span>
                        </div>

                        <div className='mt-[24px]'>
                            <div className='flex-row'>
                                <span>Reveal was created to turn confusion into clarity. This app</span>
                            </div>
                            <div className='flex-row'>
                                <span>helps pet parents decode labels, understand ingredient</span>
                            </div>
                            <div className='flex-row'>
                                <span>impacts, and save their favorite products, empowering</span>
                            </div>
                            <div className='flex-row'>
                                <span>them to make confident, informed decisions for their</span>
                            </div>
                            <div className='flex-row'>
                                <span>furry companions.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='mt-[150px] w-full items-left'>
                <div className='text-left text-[35px] text-[#555555] font-semibold'>Outcome</div>

                <div className='flex flex-row justify-between text-left mt-[80px]'>
                    <div className='font-poppins text-[23px] text-[#555555] flex flex-col w-[456px]'>
                        <span>Bringing trust and transparency to pet</span>
                        <span>food shopping.</span>
                    </div>

                    <div className='w-[488px] text-[18px] flex flex-col leading-[1.7] text-[#555555]'>
                        <div className='flex-row'>
                            <span>Reveal transforms the overwhelming process of choosing</span>
                        </div>
                        <div className='flex-row'>
                            <span>pet food into one that's simple, educational, and</span>
                        </div>
                        <div className='flex-row'>
                            <span>empowering. By integrating features like instance </span><span className='font-bold'>barcode</span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>scanning, color-coded ingredient explanations</span><span>, and</span>
                        </div>
                        <div className='flex-row'>
                            <span className='font-bold'>the ability to save and compare products</span><span>, the app</span>
                        </div>
                        <div className='flex-row'>
                            <span>provides a trusted resource for pet parents to prioritize</span>
                        </div>
                        <div className='flex-row'>
                            <span>their pets' health.</span>
                        </div>

                        <div className='mt-[24px]'>
                            <div className='flex-row'>
                                <span>Early feedback highlighted the app's ability to </span><span className='font-bold'>demystify</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>labels, reduce research time</span><span>, and provide </span><span className='font-bold'>unbiased</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>insights</span><span>, all while creating a more </span><span className='font-bold'>confident and</span>
                            </div>
                            <div className='flex-row'>
                                <span className='font-bold'>informed shopping experience.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>
            <img className='self-center w-[1024px] mt-[150px] mx-auto' src={'/assets/Reveal/Highlights.png'}/>
            <img className='self-center w-[960px] mt-[150px] mx-auto' src={'/assets/Recur/LineSperator.png'}/>

        </div>
    )
}
